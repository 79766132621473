<template>
  <div>
    <div class="class-info">
      <Heading heading="h4" :content="$t('newCourses.Info')">
        <template slot="button" v-if="session_class && roleIsTeacherOrAdmin">
          <router-link
            class="ml-2"
            :to="{
              name: 'sessionClassesEdit',
              params: { id: session_class.id }
            }"
          >
            <el-button type="success" size="mini">
              <i class="fas fa-edit" />
            </el-button>
          </router-link>
        </template>
      </Heading>
      <hr />
      <el-card>
        <el-form label-width="150px" label-position="top">
          <el-form-item label="Teacher">
            <b class="text-success">
              {{
                helper.displayMultipleItems(
                  filterTeacher(session_class.session_class_teachers)
                )
              }}
            </b>
          </el-form-item>
          <el-form-item label="Address">
            <b class="text-success">
              {{ session_class.address }}
            </b>
          </el-form-item>
          <el-form-item label="Date">
            <b class="text-success">
              <span v-if="session_class.started_on && session_class.ended_on">
                {{ session_class.started_on }} ~ {{ session_class.ended_on }}
              </span>
              <span v-else>
                -
              </span>
            </b>
          </el-form-item>
          <el-form-item label="Info">
            <b class="text-success">
              <span v-if="session_class.teacher_note">
                {{ session_class.teacher_note }}
              </span>
              <span v-else>
                -
              </span>
            </b>
          </el-form-item>
          <el-form-item
            label="Note"
            v-if="$store.getters['user/isRole']('super-admin')"
          >
            <b class="text-success">
              <span v-if="session_class.notes">
                {{ session_class.notes }}
              </span>
              <span v-else>
                -
              </span>
            </b>
          </el-form-item>
        </el-form>
      </el-card>
      <!-- <p>
        <b>Teacher:</b>
        {{
          helper.displayMultipleItems(
            session_class.session_class_teachers.map(
              ({ teacher_user }) => teacher_user.name
            )
          )
        }}
      </p>
      <p>
        <b>Address:</b>
        {{ session_class.address }}
      </p>
      <p>
        <b>Date:</b>
        <span v-if="session_class.started_on && session_class.ended_on">
          {{ session_class.started_on }} ~ {{ session_class.ended_on }}
        </span>
        <span v-else>
          -
        </span>
      </p>
      <p>
        <b>Info:</b>
        <span v-if="session_class.teacher_note">
          {{ session_class.teacher_note }}
        </span>
        <span v-else>
          -
        </span>
      </p>
      <p v-if="$store.getters['user/isRole']('super-admin')">
        <b>Note:</b>
        <span v-if="session_class.notes">
          {{ session_class.notes }}
        </span>
        <span v-else>
          -
        </span>
      </p> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user } from "@ivy-way/material";
import moment from "moment";
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import profileTest from "@/mixins/profileTest";
import courseApi from "@/apis/course";

export default {
  metaInfo() {
    return {
      title: "Info"
    };
  },

  components: {},

  mixins: [roleMixin, profileTest],
  props: [],
  data() {
    return {
      session_class: null
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    helper() {
      return helper;
    },
    roleIsTeacherOrAdmin() {
      return this.isRoleAdmin() || this.isRoleTeacher();
    }
  },
  watch: {},

  mounted() {
    this.getSessions();
  },

  methods: {
    filterTeacher(teachers) {
      let newTeachers = [];
      teachers.forEach(user => {
        if (user.is_active) {
          newTeachers.push(user.teacher_user.name);
        }
      });
      return newTeachers;
    },
    async getSessions() {
      const { session_class } = await courseApi.fetchSessionClass(
        this.$route.params.classId
      );
      this.session_class = session_class;
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__label {
  padding: 0 !important;
  margin: 0 !important;
  /* font-size: 1.1rem; */
  /* font-weight: 500; */
  line-height: 1.1rem;
}
</style>
